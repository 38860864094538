import { makeStyles } from '@mui/styles';

// eslint-disable-next-line import/prefer-default-export
export const useStyles = makeStyles({
    btnSub: {
        background: '#39E4B8',
        color: '#fff',
        '&:hover': {
            background: '#30D3AA'
        }
    },

    inkBarStyle: {
        backgroundColor: '#39E4B8'
    }

});
